<template>
    <div class="article">
        <Header/>
        <div class="banner">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>文章学习</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="search-bar">
                    <el-input v-model="key" placeholder="请输入文章相关关键词进行搜索" clearable @clear="search">
                        <el-button slot="append" type="primary" @click="search">搜索</el-button>
                    </el-input>
                </div>
            </div>
            
        </div>
        <div class="article-content">

            <div class="main">
                <div class="filter-bar">
                 <div class="filter">
                    <div class="label">行业领域： </div>
                    <div class="filter-list is-expand">
                        <div :class="industryActive===index?'filter-item active':'filter-item'" v-for="(item,index) in categories" :key="index" @click="industryItemClick(index)">{{ item.name}}</div>
                    </div>
                 </div>
                 <div class="filter">
                    <div class="label">
                        学习科目：
                    </div>

                    <div :class="expand?'filter-list is-expand':'filter-list expand-tag'">
                        <div :class="subjectActive===index?'filter-item active':'filter-item'" v-for="(item,index) in subjectList" :key="index" @click="subjectItemClick(index)">{{ item.name}}</div>
                    </div>
                     <div class="expand" @click="onExpand" v-if="showExpand">
                        <i :class="expand?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                     </div>

                    </div>

                </div>
                <div class="filter-sort">
                    <div class="sort-list">
                        <div :class="item.type===sortType?'sort-item active':'sort-item'" v-for="(item,index) in sortList" :key="index" @click="sort(item.type)">{{ item.title }}</div>
                    </div>
                    <div class="count">共找到<span>{{ total }}</span>篇文章</div>
                 </div> 
                <div class="article-list">

                    <div v-for="(item,index) in list" :key="index">

                        <article-item :item="item"></article-item>


                    </div>

                </div>
                <empty height="400px" v-if="empty"/>

                <div class="page">
                    <el-pagination
                            :hide-on-single-page="true"
                            @current-change="handelChange"
                            background
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>

            </div>

        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import ArticleItem from '@/components/ArticleItem.vue'
import Empty from '@/components/Empty'
import Footer from "../components/Footer.vue"
import { GetArticleList } from '../api/list'
    export default {
        data() {
            return {
                total:0,
                user:'',
                page:1,
                pageSize:12,
                list:[],
                empty:false,
                categories:[{id:'0',name:'全部'}],
                industryActive:-1,
                subjectList:[],
                subjectActive:0,
                categoryId:'',
                key:'',
                showExpand:false,
                expand:false,
                sortType:'0',
                sortList:[{
                    type:'0',
                    title:'综合'
                },
                {
                    type:'1',
                    title:'人气'
                },
            ]
            }
        },

        components: {
            Header,
            ArticleItem,
            Empty,
            Footer,
        },
        created () {

            this.getArticleData()
            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.getArticleData()

            })

        },
        mounted(){
            this.categories=this.categories.concat(this.$store.state.category.categories) 
            this.industryItemClick(0)
        },
        methods: {
             //行业切换
             industryItemClick(index){

if(this.industryActive===index){
  return
}  
this.industryActive=index;
this.subjectActive=0;
this.subjectList=[{id:'0',name:'全部'}]
//全部
if(this.categories[index].id==='0'){

for(let item of this.categories){
   if(item.children){
   this.subjectList=this.subjectList.concat(item.children)
  }
}


}else{
  this.subjectList=this.subjectList.concat(this.categories[index].children)
}
},
//科目切换
subjectItemClick(index){
  if(this.subjectActive===index){
  return
}  
this.subjectActive=index;

let id= this.subjectList[index].id
this.categoryId=id==='0'?'':id;
this.page=1
this.getArticleData()

},

//搜索
search(){

 this.page=1;
 this.getArticleData()
},
    //判断是否显示展开
    isShowExpand(){

let dom = document.querySelector('.expand-tag')
this.showExpand=dom.offsetWidth>1000;
},

//展开更多学习科目
onExpand(){

this.expand=!this.expand

},

//排序
sort(type){

this.sortType=type;

this.getArticleData()

},
            //加载文章数据
            getArticleData(){
                GetArticleList(this.pageSize,this.page,this.categoryId,this.key,this.sortType).then(res=> {
                    this.total = res.total;
                    this.list = res.data;
                    this.empty = this.list.length===0

                })
            },
            //分页
            handelChange(val){

                this.page=val;
                this.getArticleData();
            }
        },
        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }
    }
</script>

<style lang="less" scoped>
@import url(../assets/css/Article.css);
.article{
    .nav{
    /deep/ .el-breadcrumb__inner {
    color: #fff;
  }

  .search-bar{

    /deep/ .el-input-group__append, .el-input-group__prepend{

        background-color: #3B89FF;
        border: none;
        color: #fff;
    }
  }

}

  }
</style>
