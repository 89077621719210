<template>
    <div class="article-item" @click="jump">
        <div>
            <img class="cover" :src="item.iconPath||require('../assets/img/default_img.jpg')">
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 5px">
            <div class="title">{{item.title}}</div>
            <div class="view-count">
                <img src="../assets/img/home/uv.png"/>
                <div style="margin-left: 5px">{{item.viewCount}}</div>
                <div style="margin-left: 5px">学过</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ArticleItem",
        props:{
            item:{
                type:Object,
                default:function () {

                    return{}
                }
            }
        },
        methods:{
            //跳转
            jump(){
                this.$router.push({
                    path: `/articleDetail/${ this.item.id}`
                })

            }
        }
    }
</script>

<style lang="less" scoped>

    .article-item{
        display: flex;
        cursor: pointer;
        &:hover {

            .title{
                color: #3B89FF;
            }
        }

        .cover{
            width: 84px;
            height: 84px;
            border-radius: 4px;
            background-color: #F3F4F6;
            vertical-align: middle;
        }
        .title{
            font-size: 15px;
            flex: 1;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .view-count{
            display: flex;
            flex-shrink: 0;
            align-items: center;
            font-size: 13px;
            color: rgba(60,60,67,0.6);
            img{
                width: 12px;
                height: 12px;
            }
        }
    }

</style>
